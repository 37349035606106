<template>
  <v-dialog class="confirm" v-model="dialog">
    <v-card class="confirm__area">
      <v-card-title class="confirm__area__title">{{ title }}</v-card-title>
      <v-card-text class="confirm__area__msg">
        {{ msg }}<br><br>
        本当によろしいですか？
      </v-card-text>
      <v-btn text class="confirm__area__btn" @click="doAction()">はい</v-btn>
      <v-btn text class="confirm__area__btn--cancel" @click="dialog = false">いいえ</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    // ダイアログのタイトル
    title: {
      type: String,
      required: true
    },
    // ダイアログのメッセージ
    msg: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    // ダイアログの表示
    open () {
      this.dialog = true
    },
    // 指定関数の実行
    doAction () {
      this.dialog = false
      this.$emit('do-action')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.confirm {
  &__area {
    width: 100%;
    padding: 20px 15px;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    &__title {
      display: block;
      margin: 0;
      &.v-card__title {
        padding: 0;
        font-size: 1.4rem;
        font-weight: bold;
        color: $black_color;
      }
    }
    &__msg {
      margin: 10px 0 0;
      text-align: left;
      white-space: pre-wrap;
      &.v-card__text {
        padding: 0;
        font-size: 1rem;
        color: $gray_darken_color;
      }
    }
    &__btn {
      margin: 30px 20px 0;
      font-size: 1.4rem;
      font-weight: bold;
      color: $orange_color;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
        height: auto;
        padding: 0;
      }
      &--cancel {
        @extend .confirm__area__btn;
        color: $gray_darken_color;
      }
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
// vuetify用
.v-dialog {
  width: 73%;
  max-width: 300px;
  background-color: white;
  border-radius: 15px;
  box-shadow: none;
}

.v-overlay--active {
  .v-overlay__scrim {
    background-color: $black_color !important;
    border: none;
    opacity: 0.7 !important;
  }
}
</style>
